import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "center square"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/954fbbf6b00ec87860dcf2304cdd166b/2bef9/Morne_Mage_stepping_through_a_prismatic_archway_portal_made_of__5a5747e0-5594-41aa-932c-5036a7b95db2.png",
            "srcSet": ["/static/954fbbf6b00ec87860dcf2304cdd166b/5ff7e/Morne_Mage_stepping_through_a_prismatic_archway_portal_made_of__5a5747e0-5594-41aa-932c-5036a7b95db2.png 375w", "/static/954fbbf6b00ec87860dcf2304cdd166b/1d69c/Morne_Mage_stepping_through_a_prismatic_archway_portal_made_of__5a5747e0-5594-41aa-932c-5036a7b95db2.png 750w", "/static/954fbbf6b00ec87860dcf2304cdd166b/2bef9/Morne_Mage_stepping_through_a_prismatic_archway_portal_made_of__5a5747e0-5594-41aa-932c-5036a7b95db2.png 1024w"],
            "width": "400px",
            "className": "center square"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Farseeker manifesting a Junction`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`A `}<strong parentName="p">{`Junction`}</strong>{` is a type of manifestation of `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{` created by a `}<a parentName="p" {...{
        "href": "/Conviction",
        "title": "Conviction"
      }}>{`Conviction`}</a>{` that links two points in space. One example of Junctions is the use of `}<a parentName="p" {...{
        "href": "/Prismagates",
        "title": "Prismagates"
      }}>{`Prismagates`}</a>{`. Typically Junctions are created and maintained by `}<a parentName="p" {...{
        "href": "/Acronist#vastcaller",
        "title": "Acronist"
      }}>{`Vastcallers`}</a>{`.`}</p>
    <p>{`When a Junction is activated, a mirror-like appearance along the interior of the archway appears, reflecting different wavelengths of light in slightly different ways, giving a prismatic effect.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      